<pds-module-header title="Queries" ctaLabel="Nova Query" ctaPath="/query/create-query">
  <pds-new-breadcrumb [items]="breadcrumb"></pds-new-breadcrumb>

  <pds-panel>
    <pds-form [formGroup]="form" (ngSubmit)="search()">
      <pds-form-row>
        <pds-form-field label="Pesquisar">
          <input placeholder="Pesquise por nome" type="text" formControlName="search" (keyup.enter)="search()" />
        </pds-form-field>
      </pds-form-row>
      <pds-form-actions>
        <button pdsButton (click)="search()">Buscar</button>
      </pds-form-actions>
    </pds-form>

    <hr />

    @if (hasItems() && !loading) {
    <pds-table>
      <table>
        <thead>
          <th>Nome</th>
          <th></th>
          <th></th>
        </thead>
        <tbody>
          <tr *ngFor="let query of queriesResponse?.items">
            <td>{{ query.query }}</td>
            <td class="actions">
              <a [routerLink]="['/query', query.id]">
                <button pdsButton="icon">
                  <span class="icon">visibility</span>
                </button>
              </a>
            </td>
            <td class="actions">
              <button pdsButton="icon" (click)="delete(query)">
                <span class="icon">delete</span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <pds-pagination [pagination]="pagination$" (paginate)="onPageChange($event)"></pds-pagination>
    </pds-table>
    }@else if (loading) {
    <strong>Carregando...</strong>
    }@else if (!hasItems()) {
    <strong>Nenhuma query encontrada</strong>
    }@else {
    <strong>Falha ao listar queries</strong>
    }
  </pds-panel>
</pds-module-header>
