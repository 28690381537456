import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { QueryResponseDto } from '../../../../../dtos/responses/QueryResponseDto';
import { DialectService } from '../../../../../services/dialect.service';
import { SortablePageRequestDto } from '../../../../../dtos/requests/SortablePageRequestDto';
import { PageDto } from '../../../../../dtos/responses/PageDto';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { KeycloakCredentialsService } from '@peca/keycloak';
import { BreadcrumbItem, DesignSystemModule, Pagination, PaginationInitialState } from '@peca/design-system';
import { BehaviorSubject } from 'rxjs';
import { ConfirmableActionService } from 'apps/dialect/src/app/core/services/confirmable-action.service';

@Component({
  imports: [RouterModule, CommonModule, MatInputModule, FormsModule, ReactiveFormsModule, DesignSystemModule],
  providers: [DialectService],
  selector: 'peca-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  private confirmableAction: ConfirmableActionService;

  pagination$: BehaviorSubject<Pagination>;
  breadcrumb: BreadcrumbItem[] = [{ label: 'Queries', path: '/' }];
  queriesResponse?: PageDto<QueryResponseDto>;
  searchText = '';
  loading = true;
  form: FormGroup;

  constructor(private dialectService: DialectService, private keycloak: KeycloakCredentialsService) {
    this.confirmableAction = inject(ConfirmableActionService);

    this.pagination$ = new BehaviorSubject<Pagination>(PaginationInitialState);
    this.form = new FormGroup({
      search: new FormControl(''),
    });
  }

  ngOnInit(): void {
    this.loadQueries(this.query);
  }

  get credentials$() {
    return this.keycloak.events$;
  }

  get value() {
    return {
      search: (this.form.get('search') as FormControl).value,
    } as SortablePageRequestDto;
  }

  get query(): SortablePageRequestDto {
    const { search } = this.value;
    const { page, size } = this.pagination$.value;

    return {
      page: page - 1,
      search: search || '',
      size,
    };
  }

  loadQueries(query: SortablePageRequestDto): void {
    this.loading = true;
    this.dialectService.getQueries(query).subscribe((query: PageDto<QueryResponseDto>) => {
      this.queriesResponse = query;
      this.pagination$.next({
        items: query.totalItems,
        page: query.currentPage + 1,
        size: query.pageSize,
        total: query.totalPages,
      });
      this.loading = false;
    });
  }

  hasItems(): boolean {
    return !!this.queriesResponse?.items && this.queriesResponse.items.length > 0;
  }

  delete(query: QueryResponseDto) {
    const message = `Esta ação irá excluir esta query.`;

    this.confirmableAction.confirm(message, query, true).subscribe((confirmation) => {
      if (!confirmation.confirmed) {
        return;
      }

      this.dialectService.deleteQuery(query.id).subscribe(() => {
        const query = this.queriesResponse?.items && this.queriesResponse.items.length <= 1 ? { ...this.query, page: 0 } : this.query;
        this.loadQueries(query);
      });
    });
  }

  onPageChange(pagination: Pagination): void {
    const page = pagination.page ? pagination.page - 1 : 0;
    this.pagination$.next({ ...pagination, page });
    const query = { ...pagination, page: page };
    this.loadQueries(query);
  }

  search() {
    this.loadQueries({ ...this.query, page: 0 });
  }

  clear() {
    this.searchText = '';
    this.search();
  }
}
