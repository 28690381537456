import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DesignSystemModule } from '@peca/design-system';
import { CommonsModule } from '@peca/commons';
import { ConfirmableActionComponent } from './core/components/confirmable-action/confirmable-action.component';

@Component({
  imports: [RouterModule, DesignSystemModule, CommonsModule, ConfirmableActionComponent],
  selector: 'peca-root',
  template: `
    <router-outlet />
    <peca-toast />
    <peca-confirmable-action-2 />
  `,
})
export class AppComponent {}
