import { Component, inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BreadcrumbItem, DesignSystemModule, MultipleItem } from '@peca/design-system';
import { BehaviorSubject, firstValueFrom, map } from 'rxjs';
import { SpecificationDto } from 'apps/dialect/src/dtos/others/SpecificationDto';
import { DialectService } from 'apps/dialect/src/services/dialect.service';
import { AggregationsService } from 'apps/dialect/src/services/aggregations.service';
import { SellerService } from 'apps/dialect/src/services/sellers.service';
import { ProductGroupResponseDto } from 'apps/dialect/src/dtos/responses/ProductGroupResponseDto';
import { SpecificationResponseDto } from 'apps/dialect/src/dtos/responses/SpecificationResponseDto';
import { ToastService } from '@peca/commons';
import { QueryForm } from './query.form';
import { PDSSelectComponent } from 'libs/design-system/src/lib/components/select/select.component';
import { CreateQueryRequestDto } from 'apps/dialect/src/dtos/requests/CreateQueryRequestDto';

export interface SpecificationInput {
  availableSpecifications: SpecificationDto[];
  selectedSpecification: SpecificationDto;
}

@Component({
  imports: [RouterModule, CommonModule, MatIconModule, FormsModule, RouterModule, MatProgressSpinnerModule, DesignSystemModule, ReactiveFormsModule],
  providers: [DialectService, AggregationsService, SellerService],
  selector: 'peca-createquery',
  templateUrl: './createquery.component.html',
  styleUrls: ['./createquery.component.scss'],
})
export class CreateQueryComponent implements OnInit {
  @ViewChildren('pdsSelect') pdsSelect!: QueryList<PDSSelectComponent>;

  private sellerService: SellerService;
  private toast: ToastService;
  private route: ActivatedRoute;
  private router: Router;

  breadcrumb: BreadcrumbItem[];
  formBuilder: FormBuilder;
  formQuery: QueryForm;

  specifications$: BehaviorSubject<SpecificationResponseDto[][]>;
  productGroups$: BehaviorSubject<ProductGroupResponseDto[]>;
  whitelist$: BehaviorSubject<MultipleItem[]>;
  blacklist$: BehaviorSubject<MultipleItem[]>;

  saving = false;
  updateForm = false;
  loadingSearch = false;
  loadingSpec = false;

  constructor(private dialectService: DialectService, private aggregationsService: AggregationsService) {
    this.toast = inject(ToastService);
    this.route = inject(ActivatedRoute);
    this.router = inject(Router);
    this.sellerService = inject(SellerService);
    this.formBuilder = inject(FormBuilder);
    this.formQuery = new QueryForm();
    this.breadcrumb = [{ label: 'Queries', path: '/query' }];
    this.whitelist$ = new BehaviorSubject<MultipleItem[]>([]);
    this.blacklist$ = new BehaviorSubject<MultipleItem[]>([]);
    this.productGroups$ = new BehaviorSubject<ProductGroupResponseDto[]>([]);
    this.specifications$ = new BehaviorSubject<SpecificationResponseDto[][]>([]);
  }

  get queryId() {
    const { queryId } = this.route.snapshot.params;
    return queryId as string;
  }

  ngOnInit(): void {
    this.setupBreadcrumb();
    this.initializeForm();
  }

  private async initializeForm() {
    await this.initializeSalesChannels();

    if (this.isReadOnly) {
      this.loadExistingQuery();
    }
  }

  private async initializeSalesChannels() {
    try {
      const channels = await firstValueFrom(
        this.sellerService.suggest('').pipe(
          map((channels) =>
            channels.map((channel) => ({
              id: channel.id,
              label: channel.name,
              selected: false,
            }))
          )
        )
      );

      this.whitelist$.next(channels.map((channel) => ({ ...channel })));
      this.blacklist$.next(channels.map((channel) => ({ ...channel })));

      return channels;
    } catch (error) {
      this.toast.failure('Ocorreu uma falha ao inicializar o formulário');
      return error;
    }
  }

  private setupBreadcrumb(): void {
    this.breadcrumb.push({ label: 'Nova Query' });
  }

  private loadExistingQuery(): void {
    if (!this.queryId) return;

    this.dialectService
      .getQuery(this.queryId)
      .pipe(
        map((query) => ({
          queryText: query.query,
          productGroups: query.filter.productGroups,
          whiteList: query.saleChannelIdWhiteList || [],
          blackList: query.saleChannelIdBlackList || [],
          specifications: query.filter.specifications.map((spec) => ({
            specificationId: spec.specificationId,
            name: spec.name,
            values: spec.values,
          })),
        }))
      )
      .subscribe(({ queryText, productGroups, whiteList, blackList, specifications }) => {
        this.breadcrumb[this.breadcrumb.length - 1] = { label: queryText };
        this.formQuery.setValue(queryText, '', productGroups[0]?.id, whiteList, blackList, specifications);
        this.productGroups$.next(productGroups);
        this.specifications$.next(specifications.map((spec) => [spec]));
        this.formQuery.disableForm();
      });
  }

  get isReadOnly(): boolean {
    return !!this.queryId;
  }

  removeSpec(i: number) {
    if (i === -1) return;

    const specificationsArray = this.formQuery.controls.specifications;
    specificationsArray.removeAt(i);
  }

  searchProductGroup(resetSpecifications = true): void {
    if (this.loadingSearch) return;
    const { searchText } = this.formQuery.values;
    if (!searchText) return;

    this.loadingSearch = true;

    const productGroups = this.formQuery.controls.productGroups;
    productGroups?.setValue([]);

    this.aggregationsService.searchProductGroup(searchText).subscribe((pG) => {
      let formattedGroups: ProductGroupResponseDto[] = [];
      if (pG && pG.length > 0) {
        productGroups?.setValue(pG[0].id);
        pG.sort((a, b) => a.value.localeCompare(b.value));
        formattedGroups = pG.map((group) => ({
          id: group.id,
          name: group.value,
        }));
      }

      if (resetSpecifications) {
        this.specifications$.next([]);
        this.formQuery.specifications = [];
      }

      this.productGroups$.next(formattedGroups);
      this.loadingSearch = false;
    });
  }

  addSpec(): void {
    const selectedProductGroup = this.formQuery.values.productGroups;
    if (this.loadingSpec) return;
    if (!selectedProductGroup) return;

    this.loadingSpec = true;
    let specifications = this.formQuery.values.specifications;
    if (specifications.length === 1 && !specifications[0].name && specifications[0].values?.length <= 0) {
      specifications = [];
    }

    const specificationsFormated = specifications.map((specC) => {
      const newSpec = this.specifications$.value.flat().find((spec) => spec.specificationId === specC.specificationId);
      return {
        description: newSpec?.name || '',
        id: newSpec?.specificationId || '',
        values: specC.values,
      } as unknown as SpecificationDto;
    });

    this.aggregationsService.searchSpecifications(selectedProductGroup, specificationsFormated).subscribe((specifications) => {
      this.loadingSpec = false;
      this.specifications$.next([...this.specifications$.value, specifications]);

      this.formQuery.pushNewSpecification({
        name: '',
        specificationId: '',
        values: [],
      });
    });

    this.loadingSpec = false;
  }

  onChangeSelectPG(e: Event) {
    const el = e.target as HTMLSelectElement;

    const valueChanged = el.value;
    const productIdSelected = this.formQuery.values.productGroups;

    if (valueChanged === productIdSelected) {
      this.specifications$.next([]);
      this.formQuery.specifications = [];
    }
  }

  onSelectType(i: number): void {
    const specificationsArray = this.formQuery.controls.specifications;
    const group = specificationsArray.at(i) as FormGroup;

    const selectedTypeId = group.get('specificationId')?.value;

    const specSelected = this.specifications$.value[i]?.find((spec) => spec.specificationId === selectedTypeId);
    const options = this.getOptionsForSpecification(i);

    if (this.pdsSelect.toArray()[i]) {
      this.pdsSelect.toArray()[i].updateOptions(options);
    }

    if (specSelected) {
      group.get('values')?.enable();
      return;
    }

    group.get('values')?.disable();
  }

  disabledAddSpec() {
    const { query, searchText, productGroups, specifications } = this.formQuery.controls;

    const lastSpecIsInvalid = specifications.controls.length > 0 ? specifications.controls[specifications.controls.length - 1].valid : true;

    return !lastSpecIsInvalid || this.loadingSpec || !query.valid || !searchText.valid || !productGroups.valid;
  }

  disabledProductGroups() {
    const disable = !this.productGroups$.value || this.productGroups$.value.length <= 0 || (this.isReadOnly && !this.updateForm);

    if (disable) this.formQuery.controls.productGroups.disable();
    else this.formQuery.controls.productGroups.enable();

    return disable;
  }

  getOptionsForSpecification(index: number): any[] {
    const specificationGroup = this.specifications$.value[index];

    if (!specificationGroup) {
      return [];
    }

    const specificationSelected = specificationGroup.find((spec) => this.formQuery.values.specifications[index]?.specificationId === spec.specificationId);

    if (!specificationSelected) {
      return [];
    }

    const selectedValues = this.formQuery.values.specifications[index]?.values || [];
    const options = specificationSelected.values.map((value, i) => ({
      id: value,
      label: value,
      selected: selectedValues.includes(value),
    }));

    return options;
  }

  save() {
    if (this.saving) return;
    const { query, productGroups: productGroupsId, whiteListControl, blackListControl, specifications } = this.formQuery.values;
    const selectedProductGroup = this.productGroups$.value.find((pG) => pG.id === productGroupsId);

    const mappedSpecifications = specifications.map((specC: SpecificationResponseDto, index: number) => {
      const newSpec = this.specifications$.value[index]?.find((spec) => spec.specificationId === specC.specificationId);
      return {
        name: newSpec?.name || '',
        specificationId: newSpec?.specificationId || '',
        values: specC.values || [],
      };
    });

    if (!query || !selectedProductGroup || !mappedSpecifications) {
      this.toast.failure('Preencha todos os campos corretamente');
      return;
    }

    this.saving = true;

    const payload = this.createPayload(query, selectedProductGroup, mappedSpecifications, whiteListControl, blackListControl);

    this.saveOrUpdateQuery(payload);
  }

  private saveOrUpdateQuery(payload: CreateQueryRequestDto): void {
    const isUpdate = !!this.queryId;
    const request$ = isUpdate ? this.dialectService.updateQuery(this.queryId!, payload) : this.dialectService.saveQuery(payload);

    request$.subscribe({
      next: () => this.onSaveSuccess(isUpdate),
      error: (error) => this.onSaveError(error),
    });
  }

  private onSaveSuccess(isUpdate: boolean): void {
    const action = isUpdate ? 'atualizada' : 'criada';
    this.toast.success(`Query ${action} com sucesso`);
    this.router.navigate(['/query']);
  }

  private onSaveError(error: any): void {
    error.error.errors.forEach((err: any) => this.toast.failure(err.message));
    this.saving = false;
  }

  private createPayload(
    query: string,
    productGroup: ProductGroupResponseDto,
    specifications: SpecificationResponseDto[],
    whiteList: string[],
    blackList: string[]
  ): CreateQueryRequestDto {
    return {
      query,
      filter: {
        productGroups: [productGroup],
        specifications,
      },
      saleChannelIdWhiteList: whiteList || [],
      saleChannelIdBlackList: blackList || [],
    };
  }

  onClickCancel() {
    this.updateForm = false;
    this.formQuery.disableForm();
    this.loadExistingQuery();
  }

  onClickUpdate() {
    const { searchText, productGroups } = this.formQuery.controls;

    this.formQuery.enableForm();
    this.updateForm = true;

    const productGroupSelected = this.productGroups$.value.find((pG) => pG.id === productGroups.value);
    searchText.setValue(productGroupSelected?.name);
    this.searchProductGroup(false);
  }
}
