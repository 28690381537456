<pds-module-header title="Queries" ctaLabel="Voltar" ctaPath="/query" modifier="outlined">
  <pds-new-breadcrumb [items]="breadcrumb" />
  <pds-panel>
    <pds-form [formGroup]="formQuery.group">
      <pds-form-row>
        <pds-form-field label="Query">
          <input type="text" formControlName="query" required [disabled]="isReadOnly" />
        </pds-form-field>
      </pds-form-row>

      <h2>Grupo de Produto</h2>
      <pds-form-row grid="1">
        <pds-form-field label="Pesquisar Grupo" *ngIf="updateForm || !isReadOnly">
          <input type="text" formControlName="searchText" required (keyup.enter)="searchProductGroup()" [disabled]="loadingSearch" />
        </pds-form-field>
        <pds-form-field label="">
          <button pdsButton type="submit" (click)="searchProductGroup()" *ngIf="updateForm || !isReadOnly">
            <span class="material-symbols-outlined"> search </span>
            Buscar
            <div class="btn-spinner" *ngIf="loadingSearch">
              <mat-spinner diameter="30" strokeWidth="2"></mat-spinner>
            </div>
          </button>
        </pds-form-field>
        <pds-form-field label="Grupos de Produtos">
          <select (change)="onChangeSelectPG($event)" formControlName="productGroups" required [disabled]="disabledProductGroups()" aria-label="Selecione um grupo de produtos">
            <option *ngFor="let product of productGroups$ | async" [value]="product.id">
              {{ product.name }}
            </option>
          </select>
        </pds-form-field>
      </pds-form-row>

      <pds-form-row>
        <h2>Especificações</h2>
        @if (formQuery.values.specifications.length <= 0 ) {
        <p>Nenhuma especificação</p>
        }

        <div formArrayName="specifications" *ngFor="let specification of formQuery.controls.specifications.controls; let i = index" class="spec">
          <div class="row-specs" [formGroupName]="i">
            <pds-form-field class="type" label="Tipo">
              <select required (change)="onSelectType(i)" formControlName="specificationId">
                <option [value]="specification.specificationId" *ngFor="let specification of specifications$.value[i]">
                  {{ specification.name }}
                </option>
              </select>
            </pds-form-field>

            <pds-form-field class="value" label="Valor">
              <pds-select #pdsSelect formControlName="values" [options]="getOptionsForSpecification(i)" [disableSearch]="true"> </pds-select>
            </pds-form-field>

            <pds-form-field label="">
              <button [disabled]="formQuery.disabled" (click)="removeSpec(i)" class="buttonDel" pdsButton="icon">
                <span class="material-symbols-outlined">remove</span>
              </button>
            </pds-form-field>
          </div>
        </div>

        <pds-form-field label="">
          <button [disabled]="disabledAddSpec()" pdsButton color="primary" class="add" (click)="addSpec()" *ngIf="!isReadOnly">
            <div class="btn-content" *ngIf="!loadingSpec">
              <mat-icon>add</mat-icon>
              Adicionar Especificação
            </div>
            <div class="btn-spinner" *ngIf="loadingSpec">
              <mat-spinner diameter="30" strokeWidth="2" color="accent"></mat-spinner>
            </div>
          </button>
        </pds-form-field>
      </pds-form-row>

      <h2>Canais de vendas</h2>
      <pds-form-row grid="1-1">
        <ng-container *ngIf="whitelist$ | async as whitelist">
          <pds-form-field label="Canais habilitados">
            <pds-multiple-select placeholder="Todos" [source]="whitelist$" formControlName="whiteListControl"></pds-multiple-select>
          </pds-form-field>
        </ng-container>
        <ng-container *ngIf="blacklist$ | async as blacklist">
          <pds-form-field label="Canais desabilitados">
            <pds-multiple-select placeholder="Nenhum" [source]="blacklist$" formControlName="blackListControl"></pds-multiple-select>
          </pds-form-field>
        </ng-container>
      </pds-form-row>
      <div class="section">
        <pds-form-actions>
          @if (isReadOnly && updateForm) {
          <button pdsButton="primary" (click)="onClickCancel()">Cancelar</button>
          } @if (!isReadOnly || (isReadOnly && updateForm)) {
          <button pdsButton [disabled]="formQuery.group.invalid" (click)="save()">Salvar</button>
          }@else {
          <button pdsButton [disabled]="formQuery.group.invalid" (click)="onClickUpdate()">Editar</button>
          }
        </pds-form-actions>
      </div>
    </pds-form>
  </pds-panel>
</pds-module-header>
